import React from "react";

import { Container } from "../../styles/GlobalStyle";

export default function Page404() {
  return (
    <Container>
      <h1>Essa página não existe ou ainda será criada! </h1>
    </Container>
  );
}
