import React from "react";
import { Sections } from "./styled";
import BuscarCrianca from "../../../components/BuscarCriancas"

export default function Login() {
  return (
    <Sections>
      <BuscarCrianca />
    </Sections>
  );
}
