import React from "react";
import { Div } from "../../../styles/GlobalStyle";
import BuscarCuidadoresDosVisitadores from "../../../components/BuscarCuidadoresDosVisitadores"

export default function Login() {
  return (
    <>
      <BuscarCuidadoresDosVisitadores></BuscarCuidadoresDosVisitadores>
    </>
  );
}
